.pagination {    
    padding: 20px;
    display: inline-block;

    a, span {
        display: inline-block;
        line-height: 1em;
        transition: ease-in-out all 0.4s;
        margin: 0px 5px;
    }


    .number {
        border: 1px solid $bluelight;
        padding: 10px;

        &.current, &:hover {
            background: $blue;
            color: #fff;
        }
    }
}