.mapa-calor {
    max-width: 100%;
    height: 400px;
}

.mapa-elecciones {
    max-width: 100%;
    height: 600px;;
}

.info {
    padding: 6px 8px;
    font: 14px/16px Arial, Helvetica, sans-serif;
    background: white;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
}

.info h4 {
    margin: 0 0 5px;
    color: #777;
}

.legend {
    line-height: 18px;
    color: #555;
}

.legend i {
    width: 18px;
    height: 18px;
    float: left;
    margin-right: 8px;
    opacity: 0.7;
}

.city-info {
    display: none;
    margin-top: 30px;
    padding: 20px;
    //background: #eee;
    //color: #000
    .table th {
        text-align: left;
    }
}

.custom-marker {
    width: 32px!important;
    height: 32px!important;
}

.leaflet-popup-content-wrapper {
    border-radius: 0px!important;
    padding: 0px!important;
}

.leaflet-popup-content{
    padding: 0px!important;
    border-radius: 0px!important;
    margin: 0px!important;
    .custom-popup {
        border: 0px;
        border-left: 4px solid #555;
        padding: 20px;
    }
}