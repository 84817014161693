@mixin breakpoint($point) {
  @if $point == full { // 1920
    @media (min-width: 120em) { @content ; }
  }
  @else if $point == hd { // 1400
    @media (min-width: 87.5em) { @content ; }
  }
  @else if $point == desktop { // 1200
    @media (min-width: 75em) { @content ; }
  }
  @else if $point == laptop { // 992
    @media (min-width: 62em) { @content ; }
  }
  @else if $point == tablet { // 800
    @media (min-width: 50em) { @content ; }
  }
  @else if $point == phablet { //600
    @media (min-width: 37.5em)  { @content ; }
  }
  @else if $point == mobileonly { //600
    @media (max-width: 37.5em)  { @content ; }

  }
}