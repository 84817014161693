body{
    font-family: $font_body;    
    &[data-aos-easing] {
        overflow-x: hidden;
    }
    &.stop-overflow {
        overflow: hidden;
    }
}

::selection {
    background: $bluelight;
    color: #fff;
}

/* scroll */
::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background: #eee;
}

::-webkit-scrollbar-thumb {
    background: lighten($blue, 70%);
    border-radius: 5px;
    &:hover {
        background: lighten($blue, 60%);
    }
}

h1, h2, h3, h4, h5, h6 {
    font-family: $font_title;
    //font-weight: 400!important;
    font-weight: 700;
}

a {
    text-decoration: none;
    color: #191919;
    transition: ease-in-out color 0.4s;
    &:hover {
        color: $red;
        text-decoration: none;
    }

    &.lang {
        margin-left: 8px;
        img {
            vertical-align: middle;
        }
    }
}

a, button, input[type="submit"]{
    &:hover {
        cursor: pointer;
    }
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

.rounded {
    border-radius: 10px;
}

.black-block,
.blue-block,
.time-line-home,
.lightblue-block {
    padding: 50px 0px;

    &.not-found {
        padding-bottom: 50px;

        @include breakpoint('laptop') {
            padding-bottom: 100px;
        }
    }
}

.black-block, .blue-block {
    color: #fff;    
}

.black-block {
    background: $black;
}

.lightblue-block {
    //background: #e8e7eb;
    background: $bluelight;
}

.blue-block {
    background: $blue;
}

small {
    font-size: 70%;
    font-weight: 300;
    text-transform: uppercase;
}

.page-title {
    background: $bluelight;

    h3 {
        margin-bottom: 0px;
        margin-top: 1em;
    }
}

.breadcrumb {    
    text-transform: uppercase;
    font-weight: 300;
    margin-bottom: 1em;
    font-size: 12px;
    margin-top: 5px;
}

.embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
}

.embed-responsive::before {
    display: block;
    content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

.embed-responsive-21by9::before {
    padding-top: 42.857143%;
}

.embed-responsive-16by9::before {
    padding-top: 56.25%;
}

.embed-responsive-4by3::before {
    padding-top: 75%;
}

.embed-responsive-1by1::before {
    padding-top: 100%;
}

.alert,
.alert-ok {
    padding: 20px;    
    margin: 15px 0px;    
    display: none;
}

.alert {
    background: #ffffb1;
}

.alert-ok {
    background: $bluelight;
}

.disabled{
    color: rgb(224, 224, 224);
    &:hover {
        cursor:not-allowed;
    }
}

.bordered-bluelight {
    border: 1px solid $bluelight;
    padding: 15px;

    > h3, >h4 {
        margin-top: 0px;
    }
}

.not-found {
    padding: 50px 0px 100px;

    h1, h3 {
        text-transform: uppercase;
        font-family:  $font_body;
    }

    h1 {
        font-size: 1.5em;
    }

    h3 {
        font-size: 1.3em;
    }

    @include breakpoint('phablet') {
        h1 {
            font-size: 2.3em;
        }

        h3 {
            font-size: 1.6em;
        }
    }

    @include breakpoint('laptop') {
        padding: 0px 0px 100px;
    }
}

.perfil-content,
.timeline-content,
.informe-content {
    margin: 15px 0px;
    line-height: 1.3em;
    word-wrap: break-word;

    a {
        text-decoration: underline;
    }
}

.bg-dark {
    background-color: #343a40 !important;
}