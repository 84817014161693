.slick-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    background: #fff center no-repeat;
    border: 0px;
    opacity: 0.5;
    color: #000;
    padding: 10px;
    font-size: 20px;

    &:focus,
    &:hover {
        outline: none;
        opacity: 1;
        color: #000;
    }
}

.slick-prev {
    left: 0px;
}

.slick-next {
    right: 0px;
}

.home-slider-container {
    //position: relative;

    .item-container {
        position: relative;
    }

    .main-text {
        position: absolute;
        left: 0px;
        //        top: 50%;
        top: 20%;
        transform: translateY(-50%);
        z-index: 99;
        color: #fff;
        text-align: center;
        width: 100%;

        h1 {
            font-size: 15px;
        }
    }

    .overlay {
        background-color: #000000;
        opacity: 0.4;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        //z-index: 9;
    }

    /*+ div {
        margin-top: -4px;
    }*/

    @include breakpoint(phablet) {
        .main-text h1 {
            font-size: 30px;
        }
    }

    @include breakpoint(laptop) {
        .main-text {
            top: 50%;

            h1 {
                font-size: 100px;
            }
        }
    }
}

.home-slider {
    img {
        width: 100%;
    }
}

/*.gallery-slider  {
    img {
        //max-width: 100%;
        width: auto!important;
    }
}*/