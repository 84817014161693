.time-line-home {
    //background: #e8e7eb;

    h2, h3 {
        margin: 0px;
    }

    h2 {
        font-size: 70px;
    }

    h3 {
        font-size: 40px;
    }

    .timeline-group {
        margin-top: 40px;
        margin-bottom: 40px;
    }    
}

.timeline-group {
    position: relative;
    .timeline-line {
        background: $bluedark;
        height: 4px;
        position: absolute;
        width: 100%;
        left: 0px;
        bottom: 0px;
        display: none;

        @include breakpoint(laptop) {
            display: block;
        }
    }
}

.timeline-item .point, .timeline-block-item::before {
    width: 15px;
    height: 15px;
    background: #fff;
    border-radius: 100%;
    border: 4px solid $bluedark;
}

.timeline-item {
    .point-holder {
        position: absolute;
        bottom: -6px;
        z-index: 9;
        width: 100%;
        display: none;
        @include breakpoint(laptop) {
            display: block;
        }
    }

    .point {        
        margin: 0 auto;
    }
}

.timeline-block-item {
    width: 100%;
    overflow: auto;

    &:after {
        content: "";
        clear: both;
        display: table;
    }

    .item > p {
        margin-top: 0px;
    }

    @include breakpoint(laptop) {
        &:nth-child(2n)::before {
            left: initial;
            right: 49.2%;
        }

        &::before {
            content: "";
            //width: 8px;
            //height: 8px;
            //background: #fff;
            //box-shadow: 0 0 0 4px #fff;
            //border-radius: 100%;
            position: absolute;
            left: 49.5%;
            z-index: 2;
        }

        .item {
            width: 48%;
            float: left;
            margin-right: 2%;
        }

        &:nth-child(2n) .item {
            float: right;
            margin-right: 0%;
            margin-left: 2%;
        }
    }
}

.timeline-main {
    margin-top: 30px;
    margin-bottom: 30px;
    overflow: hidden;
}

.timeline-main .timeline-line {
    position: absolute;
    //z-index: 1;
    z-index: 0;
    top: 0;
    margin-left: auto;
    margin-right: auto;
    width: 4px;
    height: 100%;
    background: $blue;
    left: 50%;
}