.site-footer {
    background: $blue;
    color: #fff;

    a {
        color: #fff;
        &:hover {
            color: $red;
        }
    }

    .pre-footer {
        padding: 20px 0px;
    }

    .copy {
        background: $bluedark;
    }
    .copy .col-12 {
        text-align: center;
        @include breakpoint(laptop) {
            &:first-child {
                text-align: left;
            }

            &:last-child {
                text-align: right;
            }
        }
    }
}

#up {
    position: fixed;
    right: 15px;
    bottom: 95px;
    background: $black;
    opacity: 0.7;
    transition: ease-in-out opacity 0.4s;
    display: none;

    &:hover {
        opacity: 1;

        a {
            color: $bluelight;
        }
    }

    a{
        display: block;
        padding: 15px;
        line-height: 1em;
        color: $bluelight;
    }
    img {
        max-width: 100%;
    }
}

.privacidad-bar {
    background: $black;
    color: #fff;
    //padding-bottom: 20px;
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;

    a:not(.btn){ 
        color: #fff;
        text-decoration: underline;
    }
}

.social-network a{
    display: inline-block;
    margin-right: 20px;
    font-size: 1.3em;
    &:last-child {
        margin-right: 0px;
    }
}