//
// Text
//

.text-monospace {
    font-family: monospace !important;
}

// Alignment

.text-justify {
    text-align: justify !important;
}

.text-wrap {
    white-space: normal !important;
}

.text-nowrap {
    white-space: nowrap !important;
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.text-center {
    text-align: center !important;
}

.text-md-left {
    @include breakpoint(laptop) {
        text-align: left!important;
    }
}

.text-md-right {
    @include breakpoint(laptop) {
        text-align: right !important;
    }
}


// Transformation 

.text-lowercase {
    text-transform: lowercase !important;
}

.text-uppercase {
    text-transform: uppercase !important;
}

.text-capitalize {
    text-transform: capitalize !important;
}

// Weight and italics

.font-weight-light {
    font-weight: 300 !important;
}

.font-weight-normal {
    font-weight: 400 !important;
}

.font-weight-bold {
    font-weight: 700 !important;
}

.font-weight-bolder {
    font-weight: 800 !important;
}

.font-italic {
    font-style: italic !important;
}

// Contextual colors

.text-white {
    color: #fff !important;
}

.text-body {
    color: $black !important;
}

.text-red {
    color: $red;
}

.text-muted {
    color: #c6c6c6 !important;
}

.text-black-50 {
    color: rgba($black, .5) !important;
}

.text-white-50 {
    color: rgba(#fff, .5) !important;
}

// Misc

.text-decoration-none {
    text-decoration: none !important;
}

.text-break {
    word-break: break-word !important; // IE & < Edge 18
    overflow-wrap: break-word !important;
}

// Reset

.text-reset {
    color: inherit !important;
}
