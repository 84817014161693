.item-container-preso  {
    position: relative;
    //height: 100%;    
    margin-top: 15px;
    //margin-bottom: 30px;
    //padding-bottom: 30px;
    margin-bottom: 70px;

    &:hover {
        .item-img img {
            filter: grayscale(0%);
        }

        .item-text {
            background: $blue;
            border-color: $red;
            color: #fff;
            transform: translateY(-10px);
        }
    }

    .item-img img {
        width: 100%;
        filter: grayscale(100%);
        transition: ease-in-out all 0.5s;
    }

    .item-number, .item-text {
        position: absolute;
        font-family: $font_title;
    }

    .item-text {
        /*bottom: 50px;
        left: 18px;*/
        width: 80%;
        background: $bluelight;
        padding: 15px;
        margin: 0 auto;
        border-top: 3px solid $blue;
        position: absolute;
        //bottom: 20px;
        bottom: -50px;
        left: 10%;
        text-transform: uppercase;
        font-size: 11px;
        letter-spacing: 1.25px;
        transition: all ease-in-out 0.4s;
        color: #000;
    }

    .item-number {
        z-index: 1;
        background: $blue;
        color: #fff;
        font-size: 30px;
        top: -25px;
        right: 5px;
        //left: 50%;
        //transform: translateX(-50%);
        line-height: 1em;
        //text-shadow: 1px 1px 5px rgba(255, 255, 255, 0.7);
        padding: 5px;
        border-radius: 3px;
    }

    @include breakpoint(phablet) {
        height: 100%;

        .item-text {
            bottom: 25px;
        }
    }
}
