.form-container {
    padding: 15px;
    border: 1px solid lighten($blue, 70%);
    margin-top: 30px;
    margin-bottom: 30px;

    h5 {
        margin-top: 0px;
    }
}

label:not(.label-checkbox) {
    display: block;
    font-weight: 700;
    font-size: 0.8em;
    margin-bottom: 10px;
}

.label-checkbox {
    margin-right: 10px;

    &:last-child {
        margin-right: 0px;
    }
}

input,
textarea,
.file-input {
    width: 100%;
    padding: 10px;
    border-radius: 2px;
    border: 1px solid $bluelight;
    margin-bottom: 15px;
}

input,
textarea {
    &:focus {
        border-color: $bluedark;
        outline: none;
    }
}

input[type="radio"] {
    width: auto;
}

button:focus {
    outline: none;
}

.file-input {
    input {
        display: none;
    }

    .btn {
        margin-right: 5px;
    }

    .label {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 280px;
        display: inline-block;
    }
}

.was-validated {

    input,
    textarea {
        &:invalid {
            border-color: darken($red, 4%);
        }

        &:valid {
            border-color: #28a745;
        }
    }
}

.form-inline {

    @include breakpoint(laptop) {
        display: flex;

        input {
            margin-bottom: 0px;
            border-radius: 0px;
        }
    }
}

.form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem;
}

.form-check-input {
    position: absolute;
    margin-top: 0.3rem;
    margin-left: -1.25rem;
}

.form-check-input[disabled]~.form-check-label,
.form-check-input:disabled~.form-check-label {
    color: #6c757d;
}

.form-check-inline {
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 0;
    margin-right: 0.75rem;
}

.form-check-inline .form-check-label {
    margin-bottom: 0;
}

.form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0;
    margin-bottom: 0;
}