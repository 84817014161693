.btn {
    display: inline-block;
    padding: 7px 20px;
    transition: ease-in-out all 0.4s;

    &.btn-big {
        padding: 20px 40px;
        font-weight: 300;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-size: 15px;
    }

    &:hover {
        cursor: pointer;
    }
    
    &:disabled,
    &.disabled,
    &:disabled:hover,
    &.disabled:hover {
        cursor: not-allowed;
        opacity: 0.5;
        background: #ccc;
        border-color: #ccc;
        color: rgb(247, 247, 247);
    }
}

.btn-red, .btn-blue, .btn-primary {
    color: #fff;
    &:hover {
        background: transparent;        
    }
}

.btn-red {
    background: $red;    
    border: 1px solid $red;
    &:hover{

    }
}

.btn-default {
    border-radius: 0px;
    border: 1px solid lightgray;
}

.btn-blue, .btn-primary {
    background: $blue;
    border: 1px solid $blue;
    &:hover {
        color: $blue;
        border-color: $blue;
    }
}

.btn-bluelight {
    background: $bluelight;
    &:hover {
        background: $blue;
        color: #fff;
    }
}

.btn-download {    
    text-align: center;
    line-height: 1.3em;
    
    span {
        display: block;
        &:last-child {
            text-transform: uppercase;
            font-weight: 300;        
            font-size: 0.85em;    
        }
    }
}

.btn-bordered-blue {
    border: 1px solid $blue;
    background: transparent;
    color: $blue;

    &:hover {
        background: $blue;
        color: #fff;
    }
}