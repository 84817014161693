nav ul {
    padding: 0px;
    margin: 15px 0px;

    li {
        display: inline-block;
        margin-right: 5px;

        @include breakpoint(laptop) {
            margin-right: 15px;
        }

        &:last-child {
            margin-right: 0px;
        }
    }

    a {
        color: #000;
        transition: color 0.5s;
        display: inline-block;
        padding: 10px;
        position: relative;
        transition: ease-in-out color 0.5s;

        @include breakpoint(laptop) {
            font-size: 13px;
        }

        &:hover,
        &:focus {
            color: $blue;
            opacity: .9;
        }
    }

    .current a {
        color: $blue;
        opacity: 1;
    }
}

.mobile-menu {
    display: none;
    position: fixed;
    background: #fff;
    width: 100%;
    z-index: 999;

    ul li {
        display: block;
    }

    .header-nav, .header-nav .sub-menu {
        display: block;
        //text-align: center;
    }

    .sub-menu {
        padding-left: 20px;
    }

    @include breakpoint(laptop) {
        display: none !important;
    }
}

.header-nav {
    .sub-menu {
        display: none;        
    }

    .is-parent {
        position: relative;        
    }

    @include breakpoint(laptop) {
        .is-parent:hover > a:after{
            width: 100%;
        }
        .menu-item.is-parent:hover {
            overflow: visible;
        }
        .menu-item.is-parent:hover .sub-menu{
            display: block;
        }
        .sub-menu {
            position: absolute;
            background: #fff;
            z-index: 9;
            text-align: left;
            padding-bottom: 10px;
            margin-top: 0px;
            //top: 110%;

            li {
                display: block;
                margin-right: 10px;
                margin-left: 10px;
            }            
        }
    }
}

.category-header {    
    nav ul {
        text-align: center;
        margin-bottom: 40px;

        a {
            padding: 10px 5px;

            @include breakpoint(tablet) {
                padding: 10px;
            }
        }

        @include breakpoint(hd) {
            margin-top: 75px;
            margin-bottom: 110px;
        }
    }
}