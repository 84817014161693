.label {
    margin: 0px 10px;
    padding: 5px;
    background: rgb(201, 201, 201);
    font-size: 12px;
    color:#000;
    border-radius: 3px;

    &.label-purple {
        background: $blue;
        color:#fff;
    }

    &.label-violet {
        color: $blue;
        background: $bluelight;
    }
}