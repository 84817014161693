.text-monospace {
  font-family: monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 62em) {
  .text-md-left {
    text-align: left !important;
  }
}

@media (min-width: 62em) {
  .text-md-right {
    text-align: right !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: 800 !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-body {
  color: #101010 !important;
}

.text-red {
  color: #d61e00;
}

.text-muted {
  color: #c6c6c6 !important;
}

.text-black-50 {
  color: rgba(16, 16, 16, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

body {
  font-family: "Lato", sans-serif;
}
body[data-aos-easing] {
  overflow-x: hidden;
}
body.stop-overflow {
  overflow: hidden;
}

::selection {
  background: #f0eaf9;
  color: #fff;
}

/* scroll */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #eee;
}

::-webkit-scrollbar-thumb {
  background: #d2c3ed;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
  background: #b59be2;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Roboto Slab", serif;
  font-weight: 700;
}

a {
  text-decoration: none;
  color: #191919;
  transition: ease-in-out color 0.4s;
}
a:hover {
  color: #d61e00;
  text-decoration: none;
}
a.lang {
  margin-left: 8px;
}
a.lang img {
  vertical-align: middle;
}

a:hover, button:hover, input[type=submit]:hover {
  cursor: pointer;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.rounded {
  border-radius: 10px;
}

.black-block,
.blue-block,
.time-line-home,
.lightblue-block {
  padding: 50px 0px;
}
.black-block.not-found,
.blue-block.not-found,
.time-line-home.not-found,
.lightblue-block.not-found {
  padding-bottom: 50px;
}
@media (min-width: 62em) {
  .black-block.not-found,
.blue-block.not-found,
.time-line-home.not-found,
.lightblue-block.not-found {
    padding-bottom: 100px;
  }
}

.black-block, .blue-block {
  color: #fff;
}

.black-block {
  background: #101010;
}

.lightblue-block {
  background: #f0eaf9;
}

.blue-block {
  background: #20113a;
}

small {
  font-size: 70%;
  font-weight: 300;
  text-transform: uppercase;
}

.page-title {
  background: #f0eaf9;
}
.page-title h3 {
  margin-bottom: 0px;
  margin-top: 1em;
}

.breadcrumb {
  text-transform: uppercase;
  font-weight: 300;
  margin-bottom: 1em;
  font-size: 12px;
  margin-top: 5px;
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.857143%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.alert,
.alert-ok {
  padding: 20px;
  margin: 15px 0px;
  display: none;
}

.alert {
  background: #ffffb1;
}

.alert-ok {
  background: #f0eaf9;
}

.disabled {
  color: #e0e0e0;
}
.disabled:hover {
  cursor: not-allowed;
}

.bordered-bluelight {
  border: 1px solid #f0eaf9;
  padding: 15px;
}
.bordered-bluelight > h3, .bordered-bluelight > h4 {
  margin-top: 0px;
}

.not-found {
  padding: 50px 0px 100px;
}
.not-found h1, .not-found h3 {
  text-transform: uppercase;
  font-family: "Lato", sans-serif;
}
.not-found h1 {
  font-size: 1.5em;
}
.not-found h3 {
  font-size: 1.3em;
}
@media (min-width: 37.5em) {
  .not-found h1 {
    font-size: 2.3em;
  }
  .not-found h3 {
    font-size: 1.6em;
  }
}
@media (min-width: 62em) {
  .not-found {
    padding: 0px 0px 100px;
  }
}

.perfil-content,
.timeline-content,
.informe-content {
  margin: 15px 0px;
  line-height: 1.3em;
  word-wrap: break-word;
}
.perfil-content a,
.timeline-content a,
.informe-content a {
  text-decoration: underline;
}

.bg-dark {
  background-color: #343a40 !important;
}

.btn {
  display: inline-block;
  padding: 7px 20px;
  transition: ease-in-out all 0.4s;
}
.btn.btn-big {
  padding: 20px 40px;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 15px;
}
.btn:hover {
  cursor: pointer;
}
.btn:disabled, .btn.disabled, .btn:disabled:hover, .btn.disabled:hover {
  cursor: not-allowed;
  opacity: 0.5;
  background: #ccc;
  border-color: #ccc;
  color: #f7f7f7;
}

.btn-red, .btn-blue, .btn-primary {
  color: #fff;
}
.btn-red:hover, .btn-blue:hover, .btn-primary:hover {
  background: transparent;
}

.btn-red {
  background: #d61e00;
  border: 1px solid #d61e00;
}
.btn-default {
  border-radius: 0px;
  border: 1px solid lightgray;
}

.btn-blue, .btn-primary {
  background: #20113a;
  border: 1px solid #20113a;
}
.btn-blue:hover, .btn-primary:hover {
  color: #20113a;
  border-color: #20113a;
}

.btn-bluelight {
  background: #f0eaf9;
}
.btn-bluelight:hover {
  background: #20113a;
  color: #fff;
}

.btn-download {
  text-align: center;
  line-height: 1.3em;
}
.btn-download span {
  display: block;
}
.btn-download span:last-child {
  text-transform: uppercase;
  font-weight: 300;
  font-size: 0.85em;
}

.btn-bordered-blue {
  border: 1px solid #20113a;
  background: transparent;
  color: #20113a;
}
.btn-bordered-blue:hover {
  background: #20113a;
  color: #fff;
}

.label {
  margin: 0px 10px;
  padding: 5px;
  background: #c9c9c9;
  font-size: 12px;
  color: #000;
  border-radius: 3px;
}
.label.label-purple {
  background: #20113a;
  color: #fff;
}
.label.label-violet {
  color: #20113a;
  background: #f0eaf9;
}

.milestone {
  text-align: center;
}
.milestone svg {
  height: 72px;
}
.milestone h5 {
  font-size: 18px;
  letter-spacing: 1px;
  line-height: 100%;
  position: relative;
  padding-top: 16px;
}
.milestone h5:after {
  content: "";
  display: block;
  position: absolute;
  width: 20px;
  height: 3px;
  top: 0;
  background: #ffffff;
  left: 50%;
  transform: translate(-50%, 0);
}
.milestone .milestone-number {
  font-size: 48px;
}
.milestone .milestone-top {
  margin-top: 20px;
}

.informe-block {
  position: relative;
  margin-bottom: 20px;
}
.informe-block:hover .informe-text {
  background: #f0eaf9;
  color: #101010;
}
.informe-block .informe-text {
  position: absolute;
  color: #fff;
  bottom: 3px;
  left: 0px;
  width: 100%;
  padding: 15px;
  transition: ease-in-out all 0.5s;
}

.informe-text h4, .informe-text h5 {
  margin-top: 0px;
  margin-bottom: 0px;
}
.informe-text h5 {
  margin-bottom: 5px;
  font-family: "Lato", sans-serif;
  font-weight: 300;
  text-transform: uppercase;
}

.img-ong {
  border-radius: 6px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);
}

nav ul {
  padding: 0px;
  margin: 15px 0px;
}
nav ul li {
  display: inline-block;
  margin-right: 5px;
}
@media (min-width: 62em) {
  nav ul li {
    margin-right: 15px;
  }
}
nav ul li:last-child {
  margin-right: 0px;
}
nav ul a {
  color: #000;
  transition: color 0.5s;
  display: inline-block;
  padding: 10px;
  position: relative;
  transition: ease-in-out color 0.5s;
}
@media (min-width: 62em) {
  nav ul a {
    font-size: 13px;
  }
}
nav ul a:hover, nav ul a:focus {
  color: #20113a;
  opacity: 0.9;
}
nav ul .current a {
  color: #20113a;
  opacity: 1;
}

.mobile-menu {
  display: none;
  position: fixed;
  background: #fff;
  width: 100%;
  z-index: 999;
}
.mobile-menu ul li {
  display: block;
}
.mobile-menu .header-nav, .mobile-menu .header-nav .sub-menu {
  display: block;
}
.mobile-menu .sub-menu {
  padding-left: 20px;
}
@media (min-width: 62em) {
  .mobile-menu {
    display: none !important;
  }
}

.header-nav .sub-menu {
  display: none;
}
.header-nav .is-parent {
  position: relative;
}
@media (min-width: 62em) {
  .header-nav .is-parent:hover > a:after {
    width: 100%;
  }
  .header-nav .menu-item.is-parent:hover {
    overflow: visible;
  }
  .header-nav .menu-item.is-parent:hover .sub-menu {
    display: block;
  }
  .header-nav .sub-menu {
    position: absolute;
    background: #fff;
    z-index: 9;
    text-align: left;
    padding-bottom: 10px;
    margin-top: 0px;
  }
  .header-nav .sub-menu li {
    display: block;
    margin-right: 10px;
    margin-left: 10px;
  }
}

.category-header nav ul {
  text-align: center;
  margin-bottom: 40px;
}
.category-header nav ul a {
  padding: 10px 5px;
}
@media (min-width: 50em) {
  .category-header nav ul a {
    padding: 10px;
  }
}
@media (min-width: 87.5em) {
  .category-header nav ul {
    margin-top: 75px;
    margin-bottom: 110px;
  }
}

.site-header {
  background: #fff;
  background-size: cover;
  position: relative;
  padding-top: 15px;
  padding-bottom: 15px;
}
.site-header .menu-item a:after {
  content: " ";
  height: 2px;
  width: 0px;
  background: #20113a;
  display: block;
  position: absolute;
  left: 0%;
  bottom: 0px;
  transition: ease-in-out all 0.4s;
}
.site-header .menu-item a:hover, .site-header .menu-item a:focus {
  color: #20113a;
}
.site-header .menu-item a:hover:after, .site-header .menu-item a:focus:after {
  width: 100%;
  background: #20113a;
}
.site-header .current a {
  color: #d61e00;
}
.site-header .current a:after {
  width: 100%;
  background: #d61e00;
}
.site-header.fixed {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 9999;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.46);
}

.site-logo {
  margin: 5px 0px;
}

.header-nav {
  display: none;
}
@media (min-width: 62em) {
  .header-nav {
    display: block !important;
  }
}

.nav-toggle {
  padding: 10px;
  background: transparent;
  border: 1px solid transparent;
  margin: 7px 0;
  top: 50%;
  left: 50%;
}
.nav-toggle:focus {
  outline-width: 0;
}
.nav-toggle [class*=bar-] {
  background: #20113a;
  display: block;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.2s ease all;
  transition: 0.2s ease all;
  /* 
  * ENLARGED FOR PRESENTATION
  * Keep these values at the same proportion 
  * for it to look correct
  */
  border-radius: 0px;
  height: 2px;
  width: 30px;
  margin-bottom: 5px;
  /*
  * Practical values:
  * border-radius: 2px;
  * height: 2px;
  * width: 25px;
  * margin-bottom: 4px;
  */
}
.nav-toggle .bar-bot {
  margin-bottom: 0;
}
.nav-toggle.opened .bar-top {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform-origin: 15% 15%;
  transform-origin: 15% 15%;
}
.nav-toggle.opened .bar-mid {
  opacity: 0;
}
.nav-toggle.opened .bar-bot {
  -webkit-transform: rotate(45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 15% 95%;
  transform-origin: 15% 95%;
}
@media (min-width: 62em) {
  .nav-toggle {
    display: none !important;
  }
}

.top-bar {
  background: #20113a;
  color: #fff;
}
.top-bar a {
  color: #fff;
}
.top-bar .col-12 {
  text-align: center;
}
@media (min-width: 37.5em) {
  .top-bar .col-12:first-child {
    text-align: left;
  }
  .top-bar .col-12:last-child {
    text-align: right;
  }
}

.slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  background: #fff center no-repeat;
  border: 0px;
  opacity: 0.5;
  color: #000;
  padding: 10px;
  font-size: 20px;
}
.slick-arrow:focus, .slick-arrow:hover {
  outline: none;
  opacity: 1;
  color: #000;
}

.slick-prev {
  left: 0px;
}

.slick-next {
  right: 0px;
}

.home-slider-container {
  /*+ div {
      margin-top: -4px;
  }*/
}
.home-slider-container .item-container {
  position: relative;
}
.home-slider-container .main-text {
  position: absolute;
  left: 0px;
  top: 20%;
  transform: translateY(-50%);
  z-index: 99;
  color: #fff;
  text-align: center;
  width: 100%;
}
.home-slider-container .main-text h1 {
  font-size: 15px;
}
.home-slider-container .overlay {
  background-color: #000000;
  opacity: 0.4;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media (min-width: 37.5em) {
  .home-slider-container .main-text h1 {
    font-size: 30px;
  }
}
@media (min-width: 62em) {
  .home-slider-container .main-text {
    top: 50%;
  }
  .home-slider-container .main-text h1 {
    font-size: 100px;
  }
}

.home-slider img {
  width: 100%;
}

/*.gallery-slider  {
    img {
        //max-width: 100%;
        width: auto!important;
    }
}*/
.site-footer {
  background: #20113a;
  color: #fff;
}
.site-footer a {
  color: #fff;
}
.site-footer a:hover {
  color: #d61e00;
}
.site-footer .pre-footer {
  padding: 20px 0px;
}
.site-footer .copy {
  background: #170c2a;
}
.site-footer .copy .col-12 {
  text-align: center;
}
@media (min-width: 62em) {
  .site-footer .copy .col-12:first-child {
    text-align: left;
  }
  .site-footer .copy .col-12:last-child {
    text-align: right;
  }
}

#up {
  position: fixed;
  right: 15px;
  bottom: 95px;
  background: #101010;
  opacity: 0.7;
  transition: ease-in-out opacity 0.4s;
  display: none;
}
#up:hover {
  opacity: 1;
}
#up:hover a {
  color: #f0eaf9;
}
#up a {
  display: block;
  padding: 15px;
  line-height: 1em;
  color: #f0eaf9;
}
#up img {
  max-width: 100%;
}

.privacidad-bar {
  background: #101010;
  color: #fff;
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
}
.privacidad-bar a:not(.btn) {
  color: #fff;
  text-decoration: underline;
}

.social-network a {
  display: inline-block;
  margin-right: 20px;
  font-size: 1.3em;
}
.social-network a:last-child {
  margin-right: 0px;
}

.time-line-home h2, .time-line-home h3 {
  margin: 0px;
}
.time-line-home h2 {
  font-size: 70px;
}
.time-line-home h3 {
  font-size: 40px;
}
.time-line-home .timeline-group {
  margin-top: 40px;
  margin-bottom: 40px;
}

.timeline-group {
  position: relative;
}
.timeline-group .timeline-line {
  background: #170c2a;
  height: 4px;
  position: absolute;
  width: 100%;
  left: 0px;
  bottom: 0px;
  display: none;
}
@media (min-width: 62em) {
  .timeline-group .timeline-line {
    display: block;
  }
}

.timeline-item .point, .timeline-block-item::before {
  width: 15px;
  height: 15px;
  background: #fff;
  border-radius: 100%;
  border: 4px solid #170c2a;
}

.timeline-item .point-holder {
  position: absolute;
  bottom: -6px;
  z-index: 9;
  width: 100%;
  display: none;
}
@media (min-width: 62em) {
  .timeline-item .point-holder {
    display: block;
  }
}
.timeline-item .point {
  margin: 0 auto;
}

.timeline-block-item {
  width: 100%;
  overflow: auto;
}
.timeline-block-item:after {
  content: "";
  clear: both;
  display: table;
}
.timeline-block-item .item > p {
  margin-top: 0px;
}
@media (min-width: 62em) {
  .timeline-block-item:nth-child(2n)::before {
    left: initial;
    right: 49.2%;
  }
  .timeline-block-item::before {
    content: "";
    position: absolute;
    left: 49.5%;
    z-index: 2;
  }
  .timeline-block-item .item {
    width: 48%;
    float: left;
    margin-right: 2%;
  }
  .timeline-block-item:nth-child(2n) .item {
    float: right;
    margin-right: 0%;
    margin-left: 2%;
  }
}

.timeline-main {
  margin-top: 30px;
  margin-bottom: 30px;
  overflow: hidden;
}

.timeline-main .timeline-line {
  position: absolute;
  z-index: 0;
  top: 0;
  margin-left: auto;
  margin-right: auto;
  width: 4px;
  height: 100%;
  background: #20113a;
  left: 50%;
}

.form-container {
  padding: 15px;
  border: 1px solid #d2c3ed;
  margin-top: 30px;
  margin-bottom: 30px;
}
.form-container h5 {
  margin-top: 0px;
}

label:not(.label-checkbox) {
  display: block;
  font-weight: 700;
  font-size: 0.8em;
  margin-bottom: 10px;
}

.label-checkbox {
  margin-right: 10px;
}
.label-checkbox:last-child {
  margin-right: 0px;
}

input,
textarea,
.file-input {
  width: 100%;
  padding: 10px;
  border-radius: 2px;
  border: 1px solid #f0eaf9;
  margin-bottom: 15px;
}

input:focus,
textarea:focus {
  border-color: #170c2a;
  outline: none;
}

input[type=radio] {
  width: auto;
}

button:focus {
  outline: none;
}

.file-input input {
  display: none;
}
.file-input .btn {
  margin-right: 5px;
}
.file-input .label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 280px;
  display: inline-block;
}

.was-validated input:invalid,
.was-validated textarea:invalid {
  border-color: #c21b00;
}
.was-validated input:valid,
.was-validated textarea:valid {
  border-color: #28a745;
}

@media (min-width: 62em) {
  .form-inline {
    display: flex;
  }
  .form-inline input {
    margin-bottom: 0px;
    border-radius: 0px;
  }
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-label {
  margin-bottom: 0;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
  margin-bottom: 0;
}

.item-container-preso {
  position: relative;
  margin-top: 15px;
  margin-bottom: 70px;
}
.item-container-preso:hover .item-img img {
  filter: grayscale(0%);
}
.item-container-preso:hover .item-text {
  background: #20113a;
  border-color: #d61e00;
  color: #fff;
  transform: translateY(-10px);
}
.item-container-preso .item-img img {
  width: 100%;
  filter: grayscale(100%);
  transition: ease-in-out all 0.5s;
}
.item-container-preso .item-number, .item-container-preso .item-text {
  position: absolute;
  font-family: "Roboto Slab", serif;
}
.item-container-preso .item-text {
  /*bottom: 50px;
  left: 18px;*/
  width: 80%;
  background: #f0eaf9;
  padding: 15px;
  margin: 0 auto;
  border-top: 3px solid #20113a;
  position: absolute;
  bottom: -50px;
  left: 10%;
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 1.25px;
  transition: all ease-in-out 0.4s;
  color: #000;
}
.item-container-preso .item-number {
  z-index: 1;
  background: #20113a;
  color: #fff;
  font-size: 30px;
  top: -25px;
  right: 5px;
  line-height: 1em;
  padding: 5px;
  border-radius: 3px;
}
@media (min-width: 37.5em) {
  .item-container-preso {
    height: 100%;
  }
  .item-container-preso .item-text {
    bottom: 25px;
  }
}

.pagination {
  padding: 20px;
  display: inline-block;
}
.pagination a, .pagination span {
  display: inline-block;
  line-height: 1em;
  transition: ease-in-out all 0.4s;
  margin: 0px 5px;
}
.pagination .number {
  border: 1px solid #f0eaf9;
  padding: 10px;
}
.pagination .number.current, .pagination .number:hover {
  background: #20113a;
  color: #fff;
}

.mapa-calor {
  max-width: 100%;
  height: 400px;
}

.mapa-elecciones {
  max-width: 100%;
  height: 600px;
}

.info {
  padding: 6px 8px;
  font: 14px/16px Arial, Helvetica, sans-serif;
  background: white;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.info h4 {
  margin: 0 0 5px;
  color: #777;
}

.legend {
  line-height: 18px;
  color: #555;
}

.legend i {
  width: 18px;
  height: 18px;
  float: left;
  margin-right: 8px;
  opacity: 0.7;
}

.city-info {
  display: none;
  margin-top: 30px;
  padding: 20px;
}
.city-info .table th {
  text-align: left;
}

.custom-marker {
  width: 32px !important;
  height: 32px !important;
}

.leaflet-popup-content-wrapper {
  border-radius: 0px !important;
  padding: 0px !important;
}

.leaflet-popup-content {
  padding: 0px !important;
  border-radius: 0px !important;
  margin: 0px !important;
}
.leaflet-popup-content .custom-popup {
  border: 0px;
  border-left: 4px solid #555;
  padding: 20px;
}

.spinner-container {
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  position: fixed;
  z-index: 999;
  background: #fff;
}

.spinner {
  margin: 0 auto;
  width: 70px;
  text-align: center;
  transform: translateY(-50%);
  position: relative;
  top: 50%;
}
.spinner > div {
  width: 18px;
  height: 18px;
  background-color: #20113a;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}
.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}
@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.suscripcion {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.34);
  display: none;
  z-index: 99999;
}
.suscripcion .content-form {
  background: #f0eaf9;
  padding: 10px 30px;
}
.suscripcion .content {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  /*
  @include breakpoint(laptop) {
      transform: translateY(120%);
  }
  @include breakpoint(hd) {
      transform: translateY(150%);
  }*/
}

#bio_ep_close,
#bio_ep_bg {
  display: none !important;
}

.subscription-form {
  display: none;
}