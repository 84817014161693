.milestone {
    text-align: center;

    svg {
        height: 72px;
    }

    h5 {
        font-size: 18px;
        letter-spacing: 1px;
        line-height: 100%;
        position: relative;
        //margin: 16px 0;
        //padding: 16px 0;
        padding-top: 16px;

        &:after {
            content: '';
            display: block;
            position: absolute;
            width: 20px;
            height: 3px;
            top: 0;

            background: #ffffff;
            left: 50%;
            transform: translate(-50%, 0);
        }
    }

    .milestone-number {
        font-size:48px
    }

    .milestone-top {
        margin-top: 20px;
    }
}

.informe-block {
    position: relative;
    margin-bottom: 20px;

    &:hover {
        .informe-text {
            background: $bluelight;
            color: $black;
        }
    }

    .informe-text {
        position: absolute;
        //background: rgba($black, 0.72); 
        color: #fff;
        bottom: 3px;
        left: 0px;
        width: 100%;
        padding: 15px;
        transition: ease-in-out all 0.5s;
    }
}

.informe-text {
    h4, h5 {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    h5 {
        margin-bottom: 5px;
        font-family: $font_body;
        font-weight: 300;
        text-transform: uppercase;
    }
}

.img-ong {
    border-radius: 6px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);
}