.suscripcion {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background: rgba(0, 0, 0, 0.34);
    display: none;
    z-index: 99999;

    .content-form {
        background: $bluelight;
        padding: 10px 30px;
    }

    .content {
        position: relative;
        top: 50%;
        transform: translateY(-50%);

        /*
        @include breakpoint(laptop) {
            transform: translateY(120%);
        }
        @include breakpoint(hd) {
            transform: translateY(150%);
        }*/
    }
}

#bio_ep_close,
#bio_ep_bg {
    display: none !important;
}

.subscription-form {
    display: none;
}