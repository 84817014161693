.site-header {
    background: #fff;
    background-size: cover;
    position: relative;
    padding-top: 15px;
    padding-bottom: 15px;
    
    .menu-item a {

        &:after {
            content: " ";            
            height: 2px;
            width: 0px;
            background: $blue;
            display: block;
            position: absolute;
            left: 0%;
            bottom: 0px;
            //transform: translateX(-50%);
            transition: ease-in-out all 0.4s;
        }

        &:hover, &:focus {
            color: $blue;
            &:after {
                width: 100%;
                background: $blue;
            }
        }
    }

    .current a {
        color: $red;
        &:after {
            width: 100%;
            background: $red;
        }
    }

    &.fixed {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        z-index: 9999;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.46);
    }
}

.site-logo {
    //text-align: center;
    margin: 5px 0px;
}

.header-nav {
    display: none;

    @include breakpoint(laptop) {
        display: block!important;
    }
}

.nav-toggle {
    padding: 10px;
    background: transparent;
    border: 1px solid transparent;
    margin: 7px 0;
    top: 50%;
    left: 50%;
    
    &:focus {
        outline-width: 0;
    }

    [class*='bar-'] {
        background: $blue;
        display: block;
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .2s ease all;
        transition: .2s ease all;

        /* 
        * ENLARGED FOR PRESENTATION
        * Keep these values at the same proportion 
        * for it to look correct
        */
        border-radius: 0px;
        height: 2px;
        width: 30px;
        margin-bottom: 5px;

        /*
        * Practical values:
        * border-radius: 2px;
        * height: 2px;
        * width: 25px;
        * margin-bottom: 4px;
        */
    }

    .bar-bot {
        margin-bottom: 0;
    }

    &.opened .bar-top {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        -webkit-transform-origin: 15% 15%;
        transform-origin: 15% 15%;
    }

    &.opened .bar-mid {
        opacity: 0;
    }

    &.opened .bar-bot {
        -webkit-transform: rotate(45deg);
        transform: rotate(-45deg);
        -webkit-transform-origin: 15% 95%;
        transform-origin: 15% 95%;
    }

    @include breakpoint(laptop) {
        display: none !important;
    }
}

.top-bar {
    background: $blue;
    color: #fff;
    a {
        color: #fff;
    }

    .col-12 {
        text-align: center;
        @include breakpoint(phablet) {
            &:first-child {
                text-align: left;
            }
            &:last-child {
                text-align: right;
            }
        }
    }
}